import React, { useEffect, useState } from 'react';

const AccountReport = () => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const fuser = urlParams.get('id');
    const psw = urlParams.get('otp');

    if (fuser && psw) {
      fetch(`/api/?fuser=${fuser}&psw=${psw}`)
        .then((response) => {
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          return response.json();
        })
        .then((jsonData) => {
          console.log(jsonData);  // داده‌های دریافت شده را در کنسول چاپ می‌کنیم
          setData(jsonData);
          setLoading(false);
        })
        .catch((err) => {
          setError(err);
          setLoading(false);
        });
    } else {
      setError('Invalid parameters');
      setLoading(false);
    }
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  return (
    <div>
      <h1>Account Report</h1>
      <h2>Name: {data.name}</h2>
      <h3>Username: {data.username}</h3>
      <p>Total Profit: {data.total_profit}</p>
      <h3>Details:</h3>
      <ul>
        {data.details.length > 0 ? (
          data.details.map((detail, index) => (
            <li key={index}>
              <p>Chat ID: {detail.chatid}</p>
              <p>Name: {detail.name}</p>
              <p>Username: {detail.username}</p>
              <p>Charge: {detail.charge}</p>
              <p>Profit: {detail.profit}</p>
            </li>
          ))
        ) : (
          <li>No details available</li>
        )}
      </ul>
    </div>
  );
};

export default AccountReport;
