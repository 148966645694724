import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Card, Button, Table } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

const HomePage = () => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [showAccountReport, setShowAccountReport] = useState(false);


  // تابع برای کپی کردن لینک به کلیپ‌بورد
  const copyToClipboard = () => {
    navigator.clipboard.writeText("https://t.me/elamebarvipbot/?start=In78082522")
      .then(() => alert("لینک در کلیپ‌بورد کپی شد!"))
      .catch(err => console.error("کپی کردن لینک به کلیپ‌بورد ناموفق بود:", err));
  };

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const fuser = urlParams.get('id');
    const psw = urlParams.get('otp');

    if (fuser && psw) {
      setLoading(true);
      setShowAccountReport(true);
      fetch(`/api/?fuser=${fuser}&psw=${psw}`)
        .then((response) => {
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          return response.json();
        })
        .then((jsonData) => {
          setData(jsonData);
          setLoading(false);
        })
        .catch((err) => {
          setError(err);
          setLoading(false);
        });
    }
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error && !showAccountReport) {
    return (
      <Container className="mt-5" dir="rtl" style={{ backgroundColor: '#7bb36d', padding: '20px', borderRadius: '10px' }}>

      {/* نمایش لوگوی اینماد در بالای صفحه */}
      <Row className="justify-content-center mb-3">
        <Col md={8} className="text-center">
          <a referrerPolicy="origin" target="_blank" href="https://trustseal.enamad.ir/?id=542306&Code=XBTpXRq55cE4Q8DX5lnHmLhNPyvyzJF3">
            <img
              referrerPolicy="origin"
              src="https://trustseal.enamad.ir/logo.aspx?id=542306&Code=XBTpXRq55cE4Q8DX5lnHmLhNPyvyzJF3"
              alt="Enamad Logo"
              style={{ cursor: 'pointer', width: '100px', marginBottom: '20px' }}
            />
          </a>
        </Col>
      </Row>
        <Row className="justify-content-center">
          <Col md={8}>
            <Card style={{ backgroundColor: '#299177', color: 'white' }}>
              <Card.Body className="text-center" style={{ overflow: 'visible' }}>
              <Card.Title>هم فال و هم تماشا</Card.Title>
              <Card.Title>هم بار بگیرید و هم کسب درآمد کنید </Card.Title>
                <Card.Title>به اعلام بار جامع خوش آمدید</Card.Title>
                <img src="your-logo-url-here.png" alt="Logo" className="mb-3" style={{ maxWidth: '100px' }} />
                <Card.Text>
                  اعلام بار جامع، ابزاری بی‌نظیر برای رانندگان عزیز است که امکان دریافت سریع و دقیق بارها بر اساس موقعیت مکانی را فراهم می‌آورد.
                </Card.Text>
                <Card.Text>
                  با استفاده از امکانات ما، می‌توانید به سادگی موقعیت مکانی خود را ارسال کرده و به سرعت بارهای نزدیک به خود را دریافت کنید. این سیستم به گونه‌ای طراحی شده است که بدون هیچ‌گونه مزاحمت و تبلیغات اضافی، بهترین بارها را به شما پیشنهاد دهد.
                </Card.Text>

                <h5 className="mt-4">ویژگی‌های کلیدی:</h5>
                <ul>
                  <li>دریافت بار بر اساس موقعیت مکانی</li>
                  <li>بیشترین تعداد بارهای موجود</li>
                  <li>بدون تبلیغات و پیام‌های مزاحم</li>
                  <li>استفاده آسان و سریع</li>
                </ul>

                <Card.Text className="mt-4">
                  استفاده از امکانات ما برای دو هفته اول رایگان است و باتوجه به کیفیت بی‌نظیر، اگر رانندگان از آن استفاده کنند مشتری ما خواهند ماند.
                </Card.Text>

                <Card.Text className="mt-2">
                  در ابتدای شروع کار با ربات به شما یک لینک دعوت اختصاصی اختصاص داده می‌شود که می‌توانید با استفاده از آن دوستان و رانندگان را به سرویس ما دعوت کنید و همواره ۲۰ درصد از شارژ افراد دعوت شده را به عنوان پورسانت از ما دریافت کنید.
                </Card.Text>

                <Card.Text className="mt-2">
                  اگر در بازکردن لینک از طریق اینستاگرام مشکل وجود دارد روی لینک زیر بزنید تادر کلیپ بورد کپی شود و سپس لینک را در تلگرام باز کنید.
                </Card.Text>

                {/* لینک تلگرام با قابلیت کپی به کلیپ‌بورد */}
                <Card.Text className="mt-3">
                  <strong>لینک ربات تلگرام:</strong> 
                  <span 
                    onClick={copyToClipboard} 
                    style={{ 
                      display: 'block', 
                      wordWrap: 'break-word', 
                      cursor: 'pointer', 
                      color: '#cce7ff',
                      textDecoration: 'underline'
                    }}>
                    https://t.me/elamebarvipbot/?start=In78082522
                  </span>
                </Card.Text>

                <Button variant="primary" className="mt-4 w-100" style={{ backgroundColor: '#3460b3', border: 'none' }}>
                  <a href="https://t.me/elamebarvipbot/?start=In78082522" style={{ color: 'white', textDecoration: 'none' }}>
                    شروع کنید
                  </a>
                </Button>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    );
  }

  if (showAccountReport && data) {
    return (
      <Container className="mt-5" dir="rtl" style={{ backgroundColor: '#7bb36d', padding: '20px', borderRadius: '10px' }}>
        <h1>گزارش حساب شما</h1>
        <h2>{data.name} {data.username}</h2>
        <p>مجموع سود شما: {data.total_profit}</p>
        <h3>جزئیات:</h3>
        <Table striped bordered hover responsive className="mt-4" style={{ backgroundColor: '#ffffff', color: '#000' }}>
          <thead>
            <tr>
              <th>کد کاربر</th>
              <th>نام</th>
              <th>نام کاربری</th>
              <th>میزان شارژ</th>
              <th>سهم شما</th>
            </tr>
          </thead>
          <tbody>
            {data.details.length > 0 ? (
              data.details.map((detail, index) => (
                <tr key={index}>
                  <td>{detail.chatid}</td>
                  <td>{detail.name}</td>
                  <td>{detail.username}</td>
                  <td>{detail.charge}</td>
                  <td>{detail.profit}</td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="5" className="text-center">جزئیاتی موجود نیست</td>
              </tr>
            )}
          </tbody>
        </Table>
      </Container>
    );
  }

  return (
    <Container className="mt-5" dir="rtl" style={{ backgroundColor: '#3bc41f', padding: '20px', borderRadius: '10px' }}>
      {/* نمایش لوگوی اینماد در بالای صفحه */}
      <Row className="justify-content-center mb-3">
        <Col md={8} className="text-center">
          <a referrerPolicy="origin" target="_blank" href="https://trustseal.enamad.ir/?id=542306&Code=XBTpXRq55cE4Q8DX5lnHmLhNPyvyzJF3">
            <img
              referrerPolicy="origin"
              src="https://trustseal.enamad.ir/logo.aspx?id=542306&Code=XBTpXRq55cE4Q8DX5lnHmLhNPyvyzJF3"
              alt="Enamad Logo"
              style={{ cursor: 'pointer', width: '100px', marginBottom: '20px' }}
            />
          </a>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md={8}>
          <Card style={{ backgroundColor: '#299177', color: 'white' }}>
            <Card.Body className="text-center" style={{ overflow: 'visible', maxHeight: 'none' }}>
              <Card.Title>هم فال و هم تماشا</Card.Title>
              <Card.Title>هم بار بگیرید و هم کسب درآمد کنید </Card.Title>
              <Card.Title>به اعلام بار جامع خوش آمدید</Card.Title>
              <Card.Text>
                اعلام بار جامع، ابزاری بی‌نظیر برای رانندگان عزیز است که امکان دریافت سریع و دقیق بارها بر اساس موقعیت مکانی را فراهم می‌آورد.
              </Card.Text>
              <Card.Text>
                با استفاده از امکانات ما، می‌توانید به سادگی موقعیت مکانی خود را ارسال کرده و به سرعت بارهای نزدیک به خود را دریافت کنید. این سیستم به گونه‌ای طراحی شده است که بدون هیچ‌گونه مزاحمت و تبلیغات اضافی، بهترین بارها را به شما پیشنهاد دهد.
              </Card.Text>
              <h5 className="mt-4">ویژگی‌های کلیدی:</h5>
              <ul>
                <li>دریافت بار بر اساس موقعیت مکانی</li>
                <li>بیشترین تعداد بارهای موجود</li>
                <li>بدون تبلیغات و پیام‌های مزاحم</li>
                <li>استفاده آسان و سریع</li>
              </ul>
                 <Card.Text className="mt-4">
                  استفاده از امکانات ما برای دو هفته اول رایگان است و باتوجه به کیفیت بی‌نظیر، اگر رانندگان از آن استفاده کنند مشتری ما خواهند ماند.
                </Card.Text>

                <Card.Text className="mt-2">
                  در ابتدای شروع کار با ربات به شما یک لینک دعوت اختصاصی اختصاص داده می‌شود که می‌توانید با استفاده از آن دوستان و رانندگان را به سرویس ما دعوت کنید و همواره ۲۰ درصد از شارژ افراد دعوت شده را به عنوان پورسانت از ما دریافت کنید.
                </Card.Text>
                <Card.Text className="mt-2">
                  اگر در بازکردن لینک از طریق اینستاگرام مشکل وجود دارد روی لینک زیر بزنید تادر کلیپ بورد کپی شود و سپس لینک را در تلگرام باز کنید.
                </Card.Text>


                {/* لینک تلگرام با قابلیت کپی به کلیپ‌بورد */}
                <Card.Text className="mt-3">
                  <strong>لینک ربات تلگرام:</strong> 
                  <span 
                    onClick={copyToClipboard} 
                    style={{ 
                      display: 'block', 
                      wordWrap: 'break-word', 
                      cursor: 'pointer', 
                      color: '#cce7ff',
                      textDecoration: 'underline'
                    }}>
                    https://t.me/elamebarvipbot/?start=In78082522
                  </span>
                </Card.Text>


            <Button variant="primary" className="mt-4 w-100" style={{ backgroundColor: '#3460b3', border: 'none' }}>
                <a href="https://t.me/elamebarvipbot/?start=In78082522" style={{ color: 'white', textDecoration: 'none' }}>
                  شروع کنید
                </a>
              </Button>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default HomePage;
